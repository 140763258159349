<template>
  <div id="layout-default" class="container-fluid p-0">
    <nav class="navbar navbar-expand fixed-top navbar-dark bg-dark shadow">
      <div class="container-fluid">
        <div class="navbar-brand">
          <img src="@/assets/images/selgem-logo.png" />
        </div>
        <div class="collapse navbar-collapse">
          <div class="navbar-nav mx-auto">
            <router-link class="nav-link" to="/flood">
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M21.4 244c-5.9-6.6-5.2-16.7 1.4-22.6L261.6 10c15.1-13.4 37.8-13.4 52.9 0L416 99.9V48c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v108.6l73.2 64.8c6.6 5.9 7.2 16 1.4 22.6l-21.2 24c-5.9 6.6-16 7.2-22.6 1.4L288 72.1 65.2 269.4c-6.6 5.9-16.7 5.2-22.6-1.4l-21.2-24zm540.7 203.9c-21.5-2.4-42.1-10.5-57.9-22.9-14.1-11.1-34.2-11.3-48.2 0-37.9 30.4-107.2 30.4-145.7-1.5-13.5-11.2-33-9.1-46.7 1.8-38 30.1-106.9 30-145.2-1.7-13.5-11.2-33.3-8.9-47.1 2-15.5 12.2-36 20.1-57.7 22.4-7.9.8-13.6 7.8-13.6 15.7v32.2c0 9.1 7.6 16.8 16.7 16 28.8-2.5 56.1-11.4 79.4-25.9 56.5 34.6 137 34.1 192 0 56.5 34.6 137 34.1 192 0 23.3 14.2 50.9 23.3 79.1 25.8 9.1.8 16.7-6.9 16.7-16v-31.6c.1-8-5.7-15.4-13.8-16.3zM288 114.8l192 170v99.8h-.1c-16 0-31.6 5.5-43.9 15.4-12.2 9.8-31.5 15.6-51.7 15.6-21.2 0-40.7-6.1-53.5-16.7-11.7-9.7-26.1-14.8-41.6-14.8-16.2 0-32.3 5.7-45.5 16.1-12.4 9.8-31.1 15.4-51.5 15.4-21.2 0-40.7-6.1-53.5-16.7-11.6-9.6-26-14.7-41.4-14.7-.5 0-.9.1-1.4.1v-99.4L288 114.8zM320 224h-64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16z"
                  class=""
                ></path>
              </svg>
              <span>Flood</span>
            </router-link>
            <router-link class="nav-link" to="/social">
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M443.524 190.109l4.286-24c1.313-7.355-4.342-14.109-11.813-14.109h-89.045l18.909-105.89c1.313-7.355-4.342-14.11-11.813-14.11h-24.38a12 12 0 0 0-11.813 9.89L298.192 152h-111.24l18.909-105.89c1.313-7.355-4.342-14.11-11.813-14.11h-24.38a12 12 0 0 0-11.813 9.89L138.192 152H44.86a12 12 0 0 0-11.813 9.891l-4.286 24C27.448 193.246 33.103 200 40.575 200h89.045l-20 112H16.289a12 12 0 0 0-11.813 9.891l-4.286 24C-1.123 353.246 4.532 360 12.003 360h89.045L82.139 465.891C80.826 473.246 86.481 480 93.953 480h24.38a12 12 0 0 0 11.813-9.891L149.808 360h111.24l-18.909 105.891c-1.313 7.355 4.342 14.109 11.813 14.109h24.38a12 12 0 0 0 11.813-9.891L309.808 360h93.331a12 12 0 0 0 11.813-9.891l4.286-24c1.313-7.355-4.342-14.109-11.813-14.109H318.38l20-112h93.331a12 12 0 0 0 11.813-9.891zM269.62 312H158.38l20-112h111.24l-20 112z"
                  class=""
                ></path>
              </svg>
              <span>Social</span>
            </router-link>
          </div>
        </div>
        <div class="navbar-brand">
          <img src="@/assets/images/infrasel-logo-white.png" />
        </div>
      </div>
    </nav>
    <div id="layout-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-default",
};
</script>