import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const homeRoute = 'flood'

let router = new Router({
  routes: [{
    path: '/',
    name: 'landing',
    redirect: {
      name: homeRoute
    }
  }, {
    path: '/flood',
    name: 'flood',
    component: () => import('../views/Flood.vue'),
  }, {
    path: '/social',
    name: 'social',
    component: () => import('../views/Social.vue'),
  }]
})

export default router