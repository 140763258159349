<template>
  <div id="layout-empty" class="container-fluid p-0">
    <slot />
  </div>
</template>

<script>
export default {
  name: "layout-empty",
};
</script>